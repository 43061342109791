function createStickyObserver(element: HTMLElement) {
  let stickyTriggered = false;
  const originalTopPosition = element.getBoundingClientRect().top + window.scrollY;
  const style = window.getComputedStyle(element);

  // Create an observer for the element
  const observer = new IntersectionObserver((entries) => {
    const entry = entries[0];

    if (entry.isIntersecting) {
      // Element is visible, start listening for scroll events
      window.addEventListener('scroll', checkStickyState);
    } else {
      // Element is not visible, remove scroll listener
      window.removeEventListener('scroll', checkStickyState);
      element.classList.remove('stuck');
      stickyTriggered = false;
    }
  }, { threshold: 0 });

  function checkStickyState() {
    const STICKY_THRESHOLD = parseFloat(style.getPropertyValue('top'));
    const scrollY = window.scrollY;

    if (!stickyTriggered && scrollY + STICKY_THRESHOLD >= originalTopPosition) {
      element.classList.add('stuck');
      stickyTriggered = true;
    } else if (stickyTriggered && scrollY + STICKY_THRESHOLD < originalTopPosition) {
      element.classList.remove('stuck');
      stickyTriggered = false;
    }
  }

  observer.observe(element);

  // Clean up function
  return () => {
    observer.disconnect();
    window.removeEventListener('scroll', checkStickyState);
  };
}

export default createStickyObserver;
