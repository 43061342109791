import 'slick-carousel';

type PeopleFeatured = {
  elements: {
    modules: NodeListOf<HTMLElement>;
  };
  init: () => void;
  bindEvents: () => void;
  createSlider: (module: HTMLElement) => void;
  updateNavigation: (module: HTMLElement, currentSlide: number) => void;
};

const peopleFeatured: PeopleFeatured = {
  elements: {
    modules: document.querySelectorAll('.module--people-featured')
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    if (this.elements.modules.length > 0) {
      this.elements.modules.forEach(module => {
        this.createSlider(module);
      });
    }
  },

  createSlider(module: HTMLElement) {
    const slider = module.querySelector<HTMLElement>('.module__slider');
    const nav = module.querySelector<HTMLElement>('.module__slider--nav');

    if (!slider || !nav) return;

    const $slick = window.jQuery(slider);

    $slick.on('init', (_event: JQuery.Event, slick: JQuerySlick) => {
      const totalSlides = slick.slideCount || 0;

      for (let i = 0; i < totalSlides; i++) {
        nav.insertAdjacentHTML('beforeend', `<button type="button" class="slide-link" aria-label="Go to slide ${i + 1}"><svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M14.9276 0.670298C6.97533 2.70607 0.5 9.55889 0.5 15.8268C0.5 22.0947 6.97533 25.4437 14.9276 23.4021C22.8741 21.2173 29.5 14.3645 29.5 8.1023C29.5 1.84015 22.8741 -1.52031 14.9276 0.670298Z" fill="black"></path></svg></button>`);
      }

      this.updateNavigation(module, 0);
    });

    $slick.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      slide: '.module__slide',
      rows: 0,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true
    });

    $slick.on('beforeChange', (_e: JQuery.Event, _slick: JQuerySlick, _currentSlide: number, nextSlide: number) => {
      this.updateNavigation(module, nextSlide);
    });

    window.jQuery('.module__slider--nav').on('click', '.slide-link', function(this: HTMLElement) {
      const $navSet = window.jQuery(nav);
      const dotIndex = Array.from($navSet[0].children).indexOf(this);
      if ($slick.hasClass('slick-initialized')) {
        $slick.slick('slickGoTo', dotIndex);
      }
    });
  },

  updateNavigation(module: HTMLElement, currentSlide: number) {
    const nav = module.querySelector<HTMLElement>('.module__slider--nav');

    if (!nav) return;

    nav.querySelectorAll('.slide-link').forEach((link, index) => {
      link.classList.toggle('active', index === currentSlide);
    });
  }
};

export default peopleFeatured;
