type Elements = {
  module: HTMLElement | null;
  form: HTMLFormElement | null;
  sortButtons: HTMLButtonElement[];
  loadMore: HTMLButtonElement | null;
  resultsList: HTMLUListElement | null;
  showingCount: HTMLElement | null;
  totalCount: HTMLElement | null;
};

type Inputs = {
  name: HTMLInputElement | null;
  discipline: HTMLSelectElement | null;
  instrument: HTMLSelectElement | null;
  yearStart: HTMLSelectElement | null;
  yearEnd: HTMLSelectElement | null;
};

type PeopleSearch = {
  postsPerPage: number;
  elements: Elements;
  inputs: Inputs;
  init: () => void;
  setInputs: () => void;
  bindEvents: () => void;
  getValues: () => {
    page: string | null;
    sort: string | null;
    search: string | null;
    discipline: string | null;
    instrument: string | null;
    yearStart: string | null;
    yearEnd: string | null;
  };
  getResults: (page: number) => void;
  handleSortClick: (button: HTMLButtonElement) => void;
  handleLoadMoreClick: () => void;
  handleFormSubmit: (e: SubmitEvent) => void;
  updateURLParameters: (values: ReturnType<PeopleSearch['getValues']>) => void;
  setInitialStateFromURL: () => void;
};

type URLParams = {
  search?: string;
  discipline?: string;
  instrument?: string;
  yearStart?: string;
  yearEnd?: string;
  page?: string;
  sort?: string;
};

type InputKey = keyof Inputs;

type ParamToInputMap = {
  [K in keyof URLParams]?: InputKey;
};

const paramToInputMap: ParamToInputMap = {
  search: 'name',
  discipline: 'discipline',
  instrument: 'instrument',
  yearStart: 'yearStart',
  yearEnd: 'yearEnd',
};

const peopleSearch: PeopleSearch = {
  postsPerPage: 12,

  elements: {
    module: document.querySelector('.module--people-search[data-search="people"]'),
    form: document.querySelector('.module--people-search form'),
    sortButtons: Array.from(document.querySelectorAll('.module--people-search button[data-action="sort"]')),
    loadMore: document.querySelector('.module--people-search button[data-action="load-more"]'),
    resultsList: document.querySelector('.module--people-search .module__content--list ul'),
    showingCount: document.querySelector('.module--people-search .module__content--header span[data-value="showing"]'),
    totalCount: document.querySelector('.module--people-search .module__content--header span[data-value="total"]'),
  },

  inputs: {
    name: null,
    discipline: null,
    instrument: null,
    yearStart: null,
    yearEnd: null,
  },

  init() {
    if (!this.elements.module) {
      return;
    }

    this.setInputs();
    this.setInitialStateFromURL();
    this.bindEvents();
  },

  setInputs() {
    if(!this.elements.form) {
      return;
    }

    this.inputs.name = this.elements.form.querySelector('input[name="people-name"]');
    this.inputs.discipline = this.elements.form.querySelector('select[name="people-discipline"]');
    this.inputs.instrument = this.elements.form.querySelector('select[name="people-instrument"]');
    this.inputs.yearStart = this.elements.form.querySelector('select[name="people-year-start"]');
    this.inputs.yearEnd = this.elements.form.querySelector('select[name="people-year-end"]');
  },

  bindEvents() {
    this.elements.form?.addEventListener('submit', (e) => this.handleFormSubmit(e));
    this.elements.sortButtons.forEach((button) => {
      button.addEventListener('click', () => this.handleSortClick(button));
    });
    this.elements.loadMore?.addEventListener('click', () => this.handleLoadMoreClick());

    window.addEventListener('popstate', () => {
      this.setInitialStateFromURL();
      if (this.elements.module) {
        this.getResults(parseInt(this.elements.module.dataset.page || '1', 10));
      }
    });
  },

  getValues() {
    return {
      page: this.elements.module?.dataset.page || null,
      sort: this.elements.module?.dataset.sort || null,
      search: this.inputs.name?.value || null,
      discipline: this.inputs.discipline?.value || null,
      instrument: this.inputs.instrument?.value || null,
      yearStart: this.inputs.yearStart?.value || null,
      yearEnd: this.inputs.yearEnd?.value || null,
    };
  },

  getResults(page) {
    const values = this.getValues();
    const showingCount = page * this.postsPerPage;
    const isFaculty = this.elements.module?.classList.contains('faculty');

    this.elements.module?.classList.add('loading');

    this.updateURLParameters({ ...values, page: page.toString() });

    const backendData = {
      action: 'action_get_people',
      layout: isFaculty ? 'faculty' : 'default',
      selected: {
        ...values,
        page,
        discipline: values.discipline || 'all',
        name: values.search,  // Use 'name' for backend communication
        types: this.elements.module?.dataset.types || null,
      },
    };

    window.jQuery.ajax({
      method: 'POST',
      url: window.curtis.ajaxurl,
      data: backendData,
    })
    .success((success: string) => {
      let data;

      try {
        data = JSON.parse(success);
      } catch (e) {
        console.error('Failed to parse JSON response:', e);
        return;
      }

      /* update the results html */
      if(this.elements.resultsList && data.results && page > 1) {
        this.elements.resultsList.innerHTML = `${this.elements.resultsList.innerHTML}${data.results}`;
      } else if(this.elements.resultsList && data.results && (page === 1 || page === -1)) {
        this.elements.resultsList.innerHTML = data.results;
      } else if(this.elements.resultsList && !data.results) {
        this.elements.resultsList.innerHTML = '<li class="not-found">No results found</li>';
      }

      /* update the page number */
      this.elements.module?.setAttribute('data-page', `${page}`);

      /* update showing count */
      if(this.elements.showingCount && page !== -1) {
        this.elements.showingCount.textContent = `${showingCount > data.total ? data.total : showingCount}`;
      } else if (this.elements.showingCount && page === -1) {
        this.elements.showingCount.textContent = `${data.total}`;
      }

      /* update total matches */
      if(this.elements.totalCount) {
        this.elements.totalCount.textContent = `${data.total}`;
      }

      /* hide the load button if we have all results */
      if(page === -1 || showingCount >= data.total) {
        this.elements.loadMore?.classList.add('hidden');
      } else {
        this.elements.loadMore?.classList.remove('hidden');
      }
    })
    .fail((_jqXHR: JQuery.jqXHR, _textStatus: string, errorThrown: string) => {
      console.error('AJAX request failed:', errorThrown);
    })
    .always(() => this.elements.module?.classList.remove('loading'));
  },

  handleSortClick(button) {
    const sort = button.dataset.type || '';
    const page = this.elements.module?.dataset.page || '1';

    this.elements.module?.setAttribute('data-sort', sort);
    if(page !== '-1') {
      this.elements.module?.setAttribute('data-page', '1');
    }

    this.getResults(parseInt(page, 10));
  },

  handleLoadMoreClick() {
    const newPage = parseInt(this.elements.module?.dataset.page || '1') + 1;
    this.getResults(newPage);
  },

  handleFormSubmit(e) {
    e.preventDefault();

    const page = this.elements.module?.dataset.page || '1';
    if(page !== '-1') {
      this.elements.module?.setAttribute('data-page', '1');
    }

    this.getResults(parseInt(page, 10));
  },

  updateURLParameters(values: ReturnType<PeopleSearch['getValues']>) {
    const searchParams = new URLSearchParams(window.location.search);
    Object.entries(values).forEach(([ key, value ]) => {
      if (value !== null && value !== 'all') {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    const newURL = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newURL);
  },

  setInitialStateFromURL() {
    const searchParams = new URLSearchParams(window.location.search);
    const params: Partial<URLParams> = {};

    searchParams.forEach((value, key) => {
      if (key in paramToInputMap || key === 'page' || key === 'sort') {
        params[key as keyof URLParams] = value;
      }
    });

    let stateChanged = false;

    Object.entries(params).forEach(([ key, value ]) => {
      if (key === 'page' || key === 'sort') {
        this.elements.module?.setAttribute(`data-${key}`, value);
        stateChanged = true;
      } else {
        const inputKey = paramToInputMap[key as keyof URLParams];
        if (inputKey) {
          const input = this.inputs[inputKey];
          if (input && value) {
            if (input instanceof HTMLSelectElement || input instanceof HTMLInputElement) {
              input.value = value;
              stateChanged = true;
            }
          }
        }
      }
    });

    if (stateChanged) {
      this.getResults(parseInt(params.page || '1', 10));
    }
  }
};

export default peopleSearch;
