import Headroom from 'headroom.js';
import debounce from '@/scripts/helpers/debounce';

type Header = {
  headroom: Headroom | null;
  elements: {
    body: HTMLBodyElement | null;
    header: HTMLElement | null;
    logo: HTMLElement | null;
    search: HTMLElement | null;
    searchToggle: HTMLButtonElement | null
    subnav: HTMLElement | null;
    modules: HTMLElement[];
    links: HTMLAnchorElement[];
  };
  init: () => void;
  bindEvents: () => void;
  toggleClassOnScroll: (element: HTMLElement, className: string) => void;
  handleSearchToggle: () => void;
  handleAddSecondaryMenu: () => void;
  handleRemoveSecondaryMenu: () => void;
  checkForOverflow: () => void;
  getClosestModule: () => HTMLElement | null;
  updateActiveMenuItem: () => void;
};

const header: Header = {
  headroom: null,

  elements: {
    body: document.querySelector('body'),
    header: document.querySelector('.site-header'),
    logo: document.querySelector('.site-branding'),
    search: document.querySelector('.site-search'),
    searchToggle: document.querySelector('.site-search [data-action="search-toggle"]'),
    subnav: document.querySelector('.module__hero .module__menu'),
    modules: Array.from(document.querySelectorAll('.module[id]:not([id=""])')),
    links: []
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    window.addEventListener('scroll', debounce(() => this.updateActiveMenuItem(), 250));
    window.addEventListener('resize', debounce(() => this.checkForOverflow(), 250));

    if(this.elements.searchToggle) {
      this.elements.searchToggle.addEventListener('click', () => this.handleSearchToggle());
    }

    if(!this.elements.header) return;

    this.toggleClassOnScroll(this.elements.header, 'headroom--not-top');

    // const options = {
    //   offset: 80,
    //   tolerance : {
    //     up : 30,
    //     down : 0
    //   },
    //   classes: {
    //     notTop: 'header-not-top',
    //     pinned: 'header-pinned',
    //   },
    //   onPin : () => {
    //     // this.elements.body?.classList.add('header-pinned');
    //   },
    //   onUnpin : () => {
    //     // this.elements.body?.classList.remove('header-pinned');
    //   },
    //   onNotTop : () => {
    //     this.handleAddSecondaryMenu();
    //     // this.elements.body?.classList.add('header-not-top');
    //   },
    //   onTop : () => {
    //     this.handleRemoveSecondaryMenu();
    //     // this.elements.body?.classList.remove('header-not-top');
    //     this.elements.header?.classList.remove('will-scroll');
    //   }
    // };

    // this.headroom = new Headroom(this.elements.header, options);
    // this.headroom.init();
  },

  toggleClassOnScroll(element: HTMLElement, className: string, threshold: number = 40): void {
    let lastScrollPosition = 0;

    if (!element) {
      console.error('Element not found');
      return;
    }

    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (Math.abs(currentScrollPosition - lastScrollPosition) <= threshold) {
        // Ignore small scroll changes
        return;
      }

      if (currentScrollPosition > 80) {
        if (currentScrollPosition > lastScrollPosition) {
          // Scrolling down
          this.handleAddSecondaryMenu();
          this.elements.body?.classList.add('header-not-top');
          element?.classList.add(className);
        } else {
          // Scrolling up, but not near the top
          // element?.classList.remove(className);
          // element?.classList.remove('will-scroll');
          // Keep 'header-not-top' class
        }
      } else {
        // Less than or equal to 80px from the top
        this.handleRemoveSecondaryMenu();
        this.elements.body?.classList.remove('header-not-top');
        element?.classList.remove(className);
        element?.classList.remove('will-scroll');
      }

      lastScrollPosition = currentScrollPosition;
    };

    // Throttle the scroll event
    let ticking = false;
    window.addEventListener('scroll', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    });

    // Initial check
    handleScroll();
  },

  handleSearchToggle() {
    if(!this.elements.search) return;

    this.elements.search.classList.toggle('is-open');
    this.elements.search.querySelector('input')?.focus();
  },

  handleAddSecondaryMenu() {
    if (!this.elements.logo || !this.elements.subnav) return;

    // Remove existing secondary menu if it exists
    this.handleRemoveSecondaryMenu();

    const menu = document.createElement('div');
    menu.classList.add('site-secondary-menu');
    menu.innerHTML = this.elements.subnav.innerHTML;
    this.elements.logo.insertAdjacentElement('afterend', menu);

    // Use requestAnimationFrame to ensure DOM has updated
    requestAnimationFrame(() => {
      this.elements.links = Array.from(menu.querySelectorAll('a'));
      const overview = this.elements.links[0];
      if (overview) overview.classList.add('active');
      this.checkForOverflow();
    });
  },

  handleRemoveSecondaryMenu() {
    const secondaryMenu = document.querySelector('.site-secondary-menu');
    if (secondaryMenu) {
      // Remove event listeners if any were added
      secondaryMenu.remove();
    }
    this.elements.links = [];
  },

  checkForOverflow() {
    const secondaryMenu = document.querySelector('.site-secondary-menu');
    if(!secondaryMenu) return;

    if(secondaryMenu.scrollWidth > secondaryMenu.clientWidth) {
      this.elements.header?.classList.add('will-scroll');
    } else {
      this.elements.header?.classList.remove('will-scroll');
    }
  },

  getClosestModule() {
    let closest: HTMLElement | null = null;
    let closestDistance = Infinity;

    this.elements.modules.forEach(module => {
        const distance = Math.abs(module.getBoundingClientRect().top);
        if (distance < closestDistance) {
            closest = module;
            closestDistance = distance;
        }
    });

    return closest;
  },

  updateActiveMenuItem() {
    if(this.elements.links.length === 0 || this.elements.modules.length === 0) return;

    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const headerHeight = this.elements.header?.offsetHeight || 0;

    let activeIndex = 0;

    for (let i = 0; i < this.elements.modules.length; i++) {
      const module = this.elements.modules[i];
      const moduleTop = module.offsetTop - headerHeight;
      const moduleBottom = moduleTop + module.offsetHeight;

      if (scrollPosition >= moduleTop && scrollPosition < moduleBottom) {
        activeIndex = i + 1; // +1 because the first link is for the overview
        break;
      }
    }

    // If we're above the first section, set the overview (first link) as active
    if (scrollPosition < this.elements.modules[0].offsetTop - headerHeight) {
      activeIndex = 0;
    }

    // If we're at the bottom of the page, set the last link as active
    if (scrollPosition + windowHeight >= document.documentElement.scrollHeight) {
      activeIndex = this.elements.links.length - 1;
    }

    this.elements.links.forEach((link, index) => {
      if (index === activeIndex) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }
};

export default header;
