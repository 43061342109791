import lottie from 'lottie-web';

type Elements = {
  animations: HTMLDivElement[];
};

type Divider = {
  elements: Elements;
  init: () => Promise<void>;
  loadAndPlayAnimations: () => void;
  getPath: (file: string) => string;
};

const divider: Divider = {
  elements: {
    animations: [],
  },

  async init() {
    this.elements.animations = Array.from(document.querySelectorAll('.module--divider .module__animation'));
    this.loadAndPlayAnimations();
  },

  async loadAndPlayAnimations() {
    if (this.elements.animations.length > 0) {
      this.elements.animations.forEach(async (element) => {
        if(!element.dataset.file) return;
        const response = await fetch(this.getPath(element.dataset.file));
        const file = await response.json();

        lottie.loadAnimation({
          container: element,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: file,
        });
      });
    }
  },

  getPath(file) {
    return `${window.curtis.themeurl}/public/dividers/animated/${file}`;
  }
};

export default divider;
