type Elements = {
  body: HTMLElement | null;
  header: HTMLElement | null;
  nav: HTMLElement | null;
  menuToggles: HTMLButtonElement[];
  menuItems: HTMLLIElement[];
};

type Navigation = {
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
  handleMenuToggleClick: (toggle: HTMLButtonElement) => void;
  handleMenuItemClick: (item: HTMLLIElement) => void;
};

const navigation: Navigation = {
  elements: {
    body: document.querySelector('body'),
    header: document.querySelector('.site-header'),
    nav: document.querySelector('.site-menu nav'),
    menuToggles: Array.from(document.querySelectorAll('[data-action="menu-toggle"]')),
    menuItems: Array.from(document.querySelectorAll('.site-menu nav > ul > li'))
  },

  init() {
    if (!this.elements.body || !this.elements.header || !this.elements.nav || !this.elements.menuToggles.length) {
      return;
    }

    this.bindEvents();
  },

  bindEvents() {
    /* Set up the menu toggle(s) */
    this.elements.menuToggles.forEach((button) => {
      button.addEventListener('click', () => this.handleMenuToggleClick(button));
    });

    /* Set up the menu items */
    this.elements.menuItems.forEach((item) => {
      const button = item.querySelector('button[data-action="submenu-toggle"]');

      if(!button) {
        return;
      }

      button.addEventListener('click', () => this.handleMenuItemClick(item));
    });
  },

  handleMenuToggleClick(toggle) {
    const label = toggle.querySelector('.site-menu__toggle--label');
    this.elements.header?.classList.toggle('site-menu--is-open');

    if (!label) {
      return;
    }

    if (this.elements.header?.classList.contains('site-menu--is-open')) {
      this.elements.body?.classList.add('no-scroll');
      label.textContent = 'Close';
    } else {
      this.elements.body?.classList.remove('no-scroll');
      label.textContent = 'Menu';
    }
  },

  handleMenuItemClick(activeItem) {
    const wasOpen = activeItem.classList.contains('site-menu__item--is-open');

    this.elements.menuItems.forEach((item) => {
      if (wasOpen) {
        // If the active item was open (and will close), remove 'is-closed' and 'is-open' from all items
        item.classList.remove('site-menu__item--is-closed');
        item.classList.remove('site-menu__item--is-open');
      } else {
        // If the active item was closed (and will open), handle each item appropriately
        if (item !== activeItem) {
          item.classList.add('site-menu__item--is-closed');
          item.classList.remove('site-menu__item--is-open');
        } else {
          item.classList.remove('site-menu__item--is-closed');
        }
      }
    });

    // Toggle the open state of the active item
    if (!wasOpen) {
      activeItem.classList.add('site-menu__item--is-open');
    }
  }

};

export default navigation;
