import lottie from 'lottie-web';

const footer = {
  elements: {
    logo: document.querySelector('.site-footer__logo'),
  },

  init() {
    this.bindEvents();
  },

  async bindEvents() {
    if(!this.elements.logo) return;

    const response = await fetch(this.getPath());
    const file = await response.json();

    lottie.loadAnimation({
      container: this.elements.logo,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: file,
    });

  },

  getPath() {
    const numbers = [ 1, 7 ];
    const number = Math.floor(Math.random() * numbers.length);
    return `${window.curtis.themeurl}/public/animations/Curtis_Footer_Animation_${numbers[number]}.json`;
  }
};

export default footer;
