import Splide from '@splidejs/splide';

const sectionSlider = {
  elements: {
    sections: Array.from(document.querySelectorAll<HTMLElement>('.module__content--slideshow'))
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    if(!this.elements.sections.length) return;

    this.elements.sections.forEach(section => this.createSlider(section));
  },

  createSlider(section: HTMLElement) {
    const counter: HTMLElement | null = section.querySelector('[data-value="current"]');
    const slider: HTMLElement | null = section.querySelector('.splide');

    if(!slider || !counter) return;

    const buttonPrev = section.querySelector('button[data-action="previous"]');
    const buttonNext = section.querySelector('button[data-action="next"]');

    const splide = new Splide(slider, {
      type: 'loop',
      perPage: 1,
      autoplay: true,
      arrows: false,
      pagination: false,
    });

    buttonPrev?.addEventListener('click', () => {
      splide.go('<');
    });

    buttonNext?.addEventListener('click', () => {
      splide.go('>');
    });

    splide.on('mounted', () => {
      if ( splide.length === 1 ) {
        splide.options.drag = false;
      }
    });

    splide.on('moved', (newIndex) => {
      counter.textContent = `${newIndex + 1}`;
    });

    splide.mount();
  }
};

export default sectionSlider;
