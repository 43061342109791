// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

function debounce<F extends AnyFunction>(
  func: F,
  wait: number,
  immediate: boolean = false
): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout | null = null;

  return function(this: ThisParameterType<F>, ...args: Parameters<F>): void {
    const callNow = immediate && !timeout;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(this, args);
    }
  };
}

export default debounce;
