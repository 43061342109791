import Splide from '@splidejs/splide';

type Elements = {
  sliders: HTMLElement[];
};

type Quotes = {
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
  initializeSliders: () => void;
};

const quotes: Quotes = {
  elements: {
    sliders: [],
  },

  init() {
    this.elements.sliders = Array.from(document.querySelectorAll<HTMLElement>('.module--quotes .splide'));
    this.bindEvents();
  },

  bindEvents() {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.initializeSliders());
    } else {
      this.initializeSliders();
    }
  },

  initializeSliders() {
    if (this.elements.sliders.length > 0) {
      this.elements.sliders.forEach(slider => {
        const splide = new Splide(slider, {
          type: 'loop',
          perPage: 1,
          autoplay: true,
          arrows: false,
          pagination: false,
        });

        splide.on('mounted', () => {
          if (splide.length === 1) {
            splide.options = { ...splide.options, drag: false };
          }
        });

        splide.mount();
      });
    }
  }
};

export default quotes;
