import debounce from '@/scripts/helpers/debounce';

type Elements = {
  module: HTMLElement | null;
};

type Event = {
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
  handleStickyHeight: () => void;
};

const event: Event = {
  elements: {
    module: document.querySelector('.module__single--event'),
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    window.addEventListener('load', () => {
      setTimeout(() => this.handleStickyHeight(), 150);
    });

    window.addEventListener('resize', debounce(() => {
      this.handleStickyHeight();
    }, 250));
  },

  handleStickyHeight() {
    if(!this.elements.module) return;

    const stickyElement: HTMLElement | null = this.elements.module.querySelector('.module__content--secondary');
    const contentElement: HTMLElement | null = this.elements.module.querySelector('.module__content--secondary > ul');

    if (!stickyElement || !contentElement) {
      return;
    }

    const stickyHeight = contentElement.clientHeight;
    stickyElement.style.height = `${stickyHeight}px`;
  }
};

export default event;
