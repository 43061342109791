import lottie from 'lottie-web';

const designs = {
  elements: {
    figures: Array.from(document.querySelectorAll('.design-assets figure'))
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    if(!this.elements.figures.length) return;

    this.elements.figures.forEach((figure) => this.setupFigure(figure));
  },

  setupFigure(figure: Element) {
    const path = figure.getAttribute('data-path');
    const element = figure.querySelector('.design-assets__animation');

    if(path && element) {
      fetch(path)
        .then(response => response.json())
        .then(file => {
          lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: file,
          });
        });
    }
  }
};

export default designs;
