interface AccordionGroup {
  elements: {
    groups: HTMLElement[];
  };
  init: () => void;
  bindEvents: () => void;
  setupAccordion: (group: HTMLElement) => void;
  handleAccordionClick: (details: HTMLDetailsElement[], targetDetail: HTMLDetailsElement) => void;
}

const accordionGroup: AccordionGroup = {
  elements: {
    groups: Array.from(document.querySelectorAll('.module--accordion-group .module--accordion-group--items')) as HTMLElement[],
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    // if (this.elements.groups.length > 0) {
    //   this.elements.groups.forEach(group => {
    //     this.setupAccordion(group);
    //   });
    // }
  },

  setupAccordion(group: HTMLElement) {
    const details = Array.from(group.querySelectorAll('details')) as HTMLDetailsElement[];

    if (details.length > 0) {
      details.forEach(targetDetail => {
        // Calculate and set the max-height value in data-height attribute
        targetDetail.style.maxHeight = 'none'; // Temporarily set maxHeight to none to calculate full height
        const contentHeight = targetDetail.scrollHeight;
        targetDetail.setAttribute('data-height', `${contentHeight}px`);
        targetDetail.style.maxHeight = '0'; // Reset maxHeight to initial state

        targetDetail.addEventListener('click', () => this.handleAccordionClick(details, targetDetail));
      });
    }
  },

  handleAccordionClick(details: HTMLDetailsElement[], targetDetail: HTMLDetailsElement) {
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute('open');
        // detail.style.maxHeight = '0';
      } else {
        // detail.style.maxHeight = detail.getAttribute('data-height') || '0';
      }
    });
  }
};

export default accordionGroup;
