const iframes = {
  elements: {
    iframes: Array.from(document.querySelectorAll('.module__block--content iframe')),
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    if(!this.elements.iframes.length) return;

    this.elements.iframes.forEach((iframe) => this.wrap(iframe));
  },

  wrap(element: Element) {
    if(!element || !element.parentNode) return;

    const wrapper = document.createElement('div');
    wrapper.classList.add('iframe-wrapper');
    element.parentNode.insertBefore(wrapper, element);
    wrapper.appendChild(element);
  }
};

export default iframes;
