type Elements = {
  module: HTMLElement | null;
  categories: HTMLUListElement | null;
  loadMore: HTMLButtonElement | null;
  resultsList: HTMLUListElement | null;
}

type Inputs = {
  category: HTMLInputElement | null;
}

type NewsList = {
  postsPerPage: number;
  elements: Elements;
  inputs: Inputs;
  init: () => void;
  setInputs: () => void;
  bindEvents: () => void;
  getValues: () => {
    page: string | null;
    category: string | null;
  };
  getResults: (page: number) => void;
  handleCategoryChange: (input: HTMLInputElement) => void;
  handleLoadMoreClick: () => void;
  updateURLParameters: (values: ReturnType<NewsList['getValues']>) => void;
  setInitialStateFromURL: () => void;
}

const newsList: NewsList = {
  postsPerPage: 6,

  elements: {
    module: document.querySelector('.module--news-list'),
    categories: document.querySelector('.module--news-list .module__filters'),
    loadMore: document.querySelector('.module--news-list button[data-action="load-more"]'),
    resultsList: document.querySelector('.module--news-list .module__content--list ul'),
  },

  inputs: {
    category: null
  },

  init() {
    if(!this.elements.module) return;

    this.setInputs();
    this.setInitialStateFromURL();
    this.bindEvents();
    this.getResults(parseInt(this.elements.module?.dataset.page || '1', 10));
  },

  setInputs() {
    if(!this.elements.categories) return;

    this.inputs.category = this.elements.categories.querySelector('input[type="radio"]:checked');
  },

  bindEvents() {
    const radios: HTMLInputElement[] = Array.from(this.elements.categories?.querySelectorAll('input[type="radio"]') || []);
    if(radios.length > 0) {
      radios.forEach((radio) => {
        radio.addEventListener('change', () => this.handleCategoryChange(radio));
      });
    }

    this.elements.loadMore?.addEventListener('click', () => this.handleLoadMoreClick());

    window.addEventListener('popstate', () => {
      this.setInitialStateFromURL();
      if (this.elements.module) {
        this.getResults(parseInt(this.elements.module.dataset.page || '1', 10));
      }
    });
  },

  getValues() {
    return {
      page: this.elements.module?.dataset.page || '1',
      category: this.inputs.category?.value || null
    };
  },

  getResults(page) {
    const values = this.getValues();
    const showingCount = page * this.postsPerPage;

    this.elements.module?.classList.add('loading');

    this.updateURLParameters({ ...values, page: page.toString() });

    window.jQuery.ajax({
      method: 'POST',
      url: window.curtis.ajaxurl,
      data: {
        action: 'action_get_articles',
        selected: { ...values, page }
      },
    })
    .success((success: string) => {
      const data = JSON.parse(success);

      /* update the results html */
      if(this.elements.resultsList && data.results && page > 1) {
        this.elements.resultsList.innerHTML = `${this.elements.resultsList.innerHTML}${data.results}`;
      } else if(this.elements.resultsList && data.results && page === 1) {
        this.elements.resultsList.innerHTML = data.results;
      } else if(this.elements.resultsList && !data.results) {
        this.elements.resultsList.innerHTML = '<li class="not-found">No results found</li>';
      }

      /* update the page number */
      this.elements.module?.setAttribute('data-page', `${page}`);

      /* hide the load button if we have all results */
      if(page === -1 || showingCount >= data.total) {
        this.elements.loadMore?.classList.add('hidden');
      } else {
        this.elements.loadMore?.classList.remove('hidden');
      }
    })
    .fail((_jqXHR: JQuery.jqXHR, _textStatus: string, errorThrown: string) => console.log(errorThrown))
    .always(() => this.elements.module?.classList.remove('loading'));
  },

  handleCategoryChange(input: HTMLInputElement) {
    this.inputs.category = input;
    this.elements.module?.setAttribute('data-page', '1');
    this.getResults(1);
  },

  handleLoadMoreClick() {
    const newPage = parseInt(this.elements.module?.dataset.page || '1') + 1;
    this.getResults(newPage);
  },

  updateURLParameters(values: ReturnType<NewsList['getValues']>) {
    const searchParams = new URLSearchParams(window.location.search);
    Object.entries(values).forEach(([ key, value ]) => {
      if (value !== null && value !== 'all') {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    const newURL = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, '', newURL);
  },

  setInitialStateFromURL() {
    const searchParams = new URLSearchParams(window.location.search);

    const page = searchParams.get('page');
    if (page) {
      this.elements.module?.setAttribute('data-page', page);
    }

    const category = searchParams.get('category');
    if (category && this.elements.categories) {
      const radio = this.elements.categories.querySelector(`input[type="radio"][value="${category}"]`) as HTMLInputElement | null;
      if (radio) {
        radio.checked = true;
        this.inputs.category = radio;
      }
    }
  },
};

export default newsList;
