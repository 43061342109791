const typography = {
  elements: {
    colons: [],
    percentages: []
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    document.addEventListener('DOMContentLoaded', () => {
      this.setElements();
      this.adjustCharacter(':', 'colon', this.elements.colons);
      this.adjustCharacter('%', 'percent', this.elements.percentages);
    });
  },

  setElements() {
    this.elements.colons = Array.from(document.querySelectorAll('.module--statistics__item--value'));
    this.elements.percentages = Array.from(document.querySelectorAll('.module--statistics__item--value'));
  },

  adjustCharacter(character: string, name: string, elements: HTMLElement[]) {
    elements.forEach(element => {
      element.innerHTML = element.innerHTML.replace(
        new RegExp(character, 'g'),
        `<span class="character--${name}">${character}</span>`
      );
    });
  }
};

export default typography;
